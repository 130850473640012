import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { MdNavigateBefore, MdNavigateNext, MdClose } from "react-icons/md"

class Lightbox extends Component {
  state = {
    showLightbox: false,
    selectedImage: 0,
  }

  componentDidMount = () => {
    window.addEventListener("keyup", this.handleKeyUp, false)
  }

  componentWillUnmount = () => {
    window.removeEventListener("keyup", this.handleKeyUp, false)
  }

  handleClick = (e, index) => {
    e.preventDefault()
    this.setState({
      showLightbox: !this.state.showLightbox,
      selectedImage: index,
    })
  }

  closeModal = () => {
    this.setState({ showLightbox: false })
  }

  goBack = () => {
    this.setState({ selectedImage: this.state.selectedImage - 1 })
  }

  goForward = () => {
    this.setState({ selectedImage: this.state.selectedImage + 1 })
  }

  handleKeyUp = e => {
    e.preventDefault()
    const { keyCode } = e
    if (this.state.showLightbox) {
      if (keyCode === 37) {
        // Left Arrow Key
        if (this.state.selectedImage > 0) {
          this.setState({ selectedImage: this.state.selectedImage - 1 })
        }
      }
      if (keyCode === 39) {
        // Right Arrow Key
        if (this.state.selectedImage < this.props.images.length - 1) {
          this.setState({ selectedImage: this.state.selectedImage + 1 })
        }
      }
      if (keyCode === 27) {
        // Escape key
        this.setState({ showLightbox: false })
      }
    }
  }

  render() {
    const { images } = this.props
    const { showLightbox, selectedImage } = this.state
    return (
      <Fragment>
        <Gallery>
          {images.map((img, i) => (
            <GalleryItem key={img.id}>
              <a
                href={img.fluid}
                alt="Car Image"
                onClick={e => this.handleClick(e, i)}
              >
                <StyledImg sizes={img.fluid} />
              </a>
            </GalleryItem>
          ))}
        </Gallery>

        <LightboxModal
          visible={showLightbox}
          onKeyUp={e => this.handleKeyDown(e)}
        >
          <Controls>
            <Button onClick={this.closeModal} className="close-button">
              <MdClose />
            </Button>
            <LeftRight>
              <Button
                onClick={this.goBack}
                className="previous-button"
                disabled={selectedImage === 0}
              >
                <MdNavigateBefore />
              </Button>
              <Button
                onClick={this.goForward}
                className="next-button"
                disabled={selectedImage === images.length - 1}
              >
                <MdNavigateNext />
              </Button>
            </LeftRight>
          </Controls>
          <FullImageWrapper>
            <LightboxFullImage sizes={images[selectedImage].fluid} />
          </FullImageWrapper>
        </LightboxModal>
      </Fragment>
    )
  }
}

const Gallery = styled.div`
  width: 100vw;
  height: 100vw;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  @media screen and (min-width: 700px) {
    width: 60vw;
    height: 15vw;
  }
`

const GalleryItem = styled.div`
  position: relative;
  max-width: 50vw;
  max-height: 50vw;
  width: 50vw;
  height: 50vw;
  @media screen and (min-width: 700px) {
    width: 15vw;
    height: 15vw;
  }
`

const StyledImg = styled(Img)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0; /* "-1" */
  height: 100%; // or whatever
  & > img {
    object-fit: cover !important; // or whatever
    object-position: 0% 0% !important; // or whatever
  }
`

const LightboxModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  opacity: ${props => (props.visible ? "1" : "0")};
  visibility: ${props => (props.visible ? "visible" : "hidden")};
`

const Controls = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
`

const Button = styled.button`
  background: none;
  border: none;
  color: white;
  padding: 0;
  font-size: 5vw;
  :focus {
    outline: 0;
  }
  &.close-button {
    position: fixed;
    top: 2vh;
    right: 2vh;
    font-size: 3vh;
  }
  &.previous-button {
    width: 30vw;
    height: 60vh;
    text-align: left;
  }

  &.next-button {
    width: 30vw;
    height: 60vh;
    text-align: right;
  }
`

const LeftRight = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  opacity: 0;
  @media screen and (min-width: 700px) {
    opacity: 1;
  }
`

const FullImageWrapper = styled.div`
  max-height: 90vh;
  max-width: 1000px;
  width: 100vw;
  margin: auto;
  @media screen and (min-width: 700px) {
    max-height: 100vh;
  }
`

const LightboxFullImage = styled(Img)`
  max-width: 100%;
  max-height: 90vh;
  margin: auto;
  & > img {
    object-fit: cover !important; // or whatever
    object-position: 0% 0% !important; // or whatever
  }
  @media screen and (min-width: 500px) {
    max-width: 70%;
    max-height: 100vh;
  }
  @media screen and (min-width: 700px) {
    max-width: 58%;
    max-height: 100vh;
  }
`

Lightbox.propTypes = {
  images: PropTypes.array.isRequired,
}

export default Lightbox
