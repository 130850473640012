import React from "react"
import styled from "styled-components"

import Lightbox from "./Lightbox"

const PhotoGallery = ({ photoGallery }) => {
  const gallery = photoGallery
  console.log(gallery)

  const photos = gallery.photoGallery
  console.log(photos)

  return (
    <GalleryContainer>
      <CategoryName>{photoGallery.category}</CategoryName>
      <Lightbox images={photos} />
    </GalleryContainer>
  )
}

const GalleryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 10vh 0;
`

const CategoryName = styled.h3`
  width: 100%;
  margin: 0;
  font-family: "Mukta", sans-serif;
  font-size: 3vh;
  font-weight: 200;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  @media screen and (min-width: 700px) {
    font-size: 2vh;
    text-align: left;
  }
`

export default PhotoGallery
