import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import styled from "styled-components"

import PhotoGallery from "../components/PhotoGallery"

const getPhotoGalleries = graphql`
  query {
    photoGalleries: contentfulPhotoGalleries {
      title
      photoGalleries {
        __typename
        ... on ContentfulPhotoGallery {
          id
          category
          photoGallery {
            title
            id
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

const Works = ({ location }) => {
  const response = useStaticQuery(getPhotoGalleries)
  // console.log(response)

  const photoGalleries = response.photoGalleries.photoGalleries
  // console.log(photoGalleries)

  return (
    <Layout location={location}>
      <SEO title="Works" />
      <PhotoGalleriesContainer>
        {photoGalleries.map((photoGallery, index) => (
          <PhotoGallery key={index} photoGallery={photoGallery} />
        ))}
      </PhotoGalleriesContainer>
    </Layout>
  )
}

const PhotoGalleriesContainer = styled.div`
  max-width: 800px;
  width: 100vw;
  height: 100%;
  margin-top: 10vh;
  @media screen and (min-width: 700px) {
    width: 100%;
    height: 100%;
    margin-top: 5vh;
  }
`

export default Works
